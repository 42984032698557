import React from "react";
import { StaticImage } from "gatsby-plugin-image"
// import BannerVideo from "../../images/sirius_video_dubai.mp4"
import bannerImg from "../../images/waterfront_home_banner.jpg"

const HomeBannerImage = () => {

    return (
        <img src={bannerImg} layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid banner-img" />
        // <StaticImage src="../../images/waterfront_home_banner.jpg" layout="fullWidth" formats={["webp"]} alt="banner" quality={100} className="img-fluid banner-img" />
        // <video
        //     src={BannerVideo}
        //     alt="banner-img"
        //     className="banner-img"
        //     // ref={props.videoRef}
        //     autoPlay="autoplay" playsInLine="playsinline" loop="true" muted="true"
        // />
    )

}

export default HomeBannerImage